<template>
  <div>
    <v-row class="my-4">
      <v-col cols="12" md="10" class="px-4" v-if="besuche.length > 0">
        <h2 class="white--text font-weight-light">
          Meine aktiven
          <b>CheckIns</b>
        </h2>
      </v-col>
      <v-col cols="12" md="10" class="px-4" v-if="besuche.length == 0">
        <h2 class="white--text font-weight-light">
          Willkommen,
          <br />
          <span class="font-weight-bold">{{ user.displayName }}</span>
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-4" v-if="besuche.length == 0">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl pb-0"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h3>Du hast derzeit keine aktiven CheckIns</h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          class="rounded-xl"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h3>Deine zuletzt besuchten Vereine:</h3>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="11" v-for="(v, index) in vereine" :key="index">
              <v-card
                :color="template.colors.secondary"
                :style="`color: ${template.colors.inline_secondary_text};`"
                class="rounded-xl"
                :to="`/${v.verein.alias}`"
              >
                <v-row justify="center">
                  <v-col cols="auto" align-self="center">
                    <v-avatar size="70" tile>
                      <v-img :src="v.verein.logo"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col align-self="center">
                    <h3>{{ v.verein.name }}</h3>
                    <small>
                      Letzter Besuch am {{ parseDate1(v.zutritt.datum) }}
                    </small>
                  </v-col>
                  <v-col cols="auto" align-self="center">
                    <v-icon
                      x-large
                      :color="template.colors.inline_secondary_text"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="besuche.length > 0">
      <v-col cols="12" v-for="b in besuche" :key="b.id">
        <v-card
          :color="template.colors.blocks"
          :light="!template.isDark(template.colors.blocks)"
          :dark="template.isDark(template.colors.blocks)"
          class="rounded-xl pb-0"
          :to="`/user/tickets/${b.id}`"
        >
          <v-row class="px-4">
            <v-col cols="auto" align-self="center">
              <v-avatar :size="$vuetify.breakpoint.smAndUp ? '70px' : '50px'">
                <v-img contain :src="b.verein.logo"></v-img>
              </v-avatar>
            </v-col>
            <v-col>
              <v-row>
                <v-col v-if="b.veranstaltung.id == 'ANDERE'">
                  <h3
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="font-weight-light"
                  >
                    Andere Veranstaltung
                  </h3>
                  <h2
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="font-weight-bold"
                  >
                    {{ b.verein.name }}
                  </h2>
                  <h5
                    v-if="!$vuetify.breakpoint.smAndUp"
                    class="font-weight-light"
                  >
                    Andere Veranstaltung
                  </h5>
                  <h3
                    v-if="!$vuetify.breakpoint.smAndUp"
                    class="font-weight-bold"
                  >
                    {{ b.verein.name }}
                  </h3>
                </v-col>
                <v-col v-if="b.veranstaltung.id != 'ANDERE'">
                  <h3
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="font-weight-light"
                  >
                    {{ b.veranstaltung.art }}
                  </h3>
                  <h2 v-if="$vuetify.breakpoint.smAndUp">
                    <span
                      class="font-weight-bold"
                      v-if="b.veranstaltung.art == 'Training'"
                    >
                      {{ b.veranstaltung.mannschaft }}
                    </span>
                    <span
                      class="font-weight-bold"
                      v-if="
                        b.veranstaltung.art == 'Wettkampf' ||
                        b.veranstaltung.art == 'Versammlung'
                      "
                    >
                      {{ b.veranstaltung.name }}
                    </span>
                    <span
                      class="font-weight-bold"
                      v-if="
                        b.veranstaltung.art == 'Freundschaftsspiel' ||
                        b.veranstaltung.art == 'Meisterschaftsspiel'
                      "
                    >
                      <small>
                        {{ b.veranstaltung.mannschaft }} -
                        {{ b.veranstaltung.gastmannschaft }}
                      </small>
                    </span>
                  </h2>
                  <h5
                    class="font-weight-light"
                    v-if="!$vuetify.breakpoint.smAndUp"
                  >
                    {{ b.veranstaltung.art }}
                  </h5>
                  <h3 v-if="!$vuetify.breakpoint.smAndUp">
                    <span
                      class="font-weight-bold"
                      v-if="b.veranstaltung.art == 'Training'"
                    >
                      {{ b.veranstaltung.mannschaft }}
                    </span>
                    <span
                      class="font-weight-bold"
                      v-if="
                        b.veranstaltung.art == 'Wettkampf' ||
                        b.veranstaltung.art == 'Versammlung'
                      "
                    >
                      {{ b.veranstaltung.name }}
                    </span>
                    <span
                      class="font-weight-bold"
                      v-if="
                        b.veranstaltung.art == 'Freundschaftsspiel' ||
                        b.veranstaltung.art == 'Meisterschaftsspiel'
                      "
                    >
                      <small>
                        {{ b.veranstaltung.mannschaft }} -
                        {{ b.veranstaltung.gastmannschaft }}
                      </small>
                    </span>
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-icon large>mdi-chevron-right</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Start',
  data() {
    return {
      besuche: [],
      vereine: [],
      date: '',
      currenttime: '',
      dialog: false,
      datum: '',
      tabs: 1,
      checking_out: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    var currentdate = new Date()
    var datestring =
      currentdate.getFullYear() +
      '-' +
      (currentdate.getMonth() + 1 < 10
        ? '0' + (currentdate.getMonth() + 1)
        : currentdate.getMonth() + 1) +
      '-' +
      (currentdate.getDate() < 10
        ? '0' + currentdate.getDate()
        : currentdate.getDate())
    var timestring =
      (currentdate.getHours() < 10
        ? '0' + currentdate.getHours()
        : currentdate.getHours()) +
      ':' +
      (currentdate.getMinutes() < 10
        ? '0' + currentdate.getMinutes()
        : currentdate.getMinutes()) +
      ':' +
      (currentdate.getSeconds() < 10
        ? '0' + currentdate.getSeconds()
        : currentdate.getSeconds())

    firebase
      .firestore()
      .collection('User')
      .doc(this.user.uid)
      .collection('Besuche')
      .where('zutritt.datum', '==', datestring)
      .where('zutritt.uhrzeit', '<=', timestring)
      .onSnapshot(
        (querySnap) => {
          this.besuche = []
          currentdate = new Date()
          datestring =
            currentdate.getFullYear() +
            '-' +
            (currentdate.getMonth() + 1 < 10
              ? '0' + (currentdate.getMonth() + 1)
              : currentdate.getMonth() + 1) +
            '-' +
            (currentdate.getDate() < 10
              ? '0' + currentdate.getDate()
              : currentdate.getDate())
          timestring =
            (currentdate.getHours() < 10
              ? '0' + currentdate.getHours()
              : currentdate.getHours()) +
            ':' +
            (currentdate.getMinutes() < 10
              ? '0' + currentdate.getMinutes()
              : currentdate.getMinutes())
          querySnap.forEach((doc) => {
            var b = doc.data()
            if (b.verlassen.timestamp > Date.now()) {
              this.besuche.push(b)
              var index = this.besuche.length - 1
              this.besuche[index].id = doc.id
            }
          })
        },
        (error) => {
          console.log(error)
        },
      )

    firebase
      .firestore()
      .collection('User')
      .doc(this.user.uid)
      .collection('Besuche')
      .orderBy('verlassen.datum', 'desc')
      .orderBy('verlassen.uhrzeit', 'desc')
      .limit(3)
      .get()
      .then((querySnap) => {
        this.vereine = []
        querySnap.forEach((doc) => {
          var document = doc.data()
          var exists = false
          var i
          for (i = 0; i < this.vereine.length; i++) {
            if (document.verein.alias == this.vereine[i].verein.alias) {
              exists = true
            }
          }
          if (!exists) {
            this.vereine.push(document)
            var index = this.vereine.length - 1
            this.vereine[index].id = doc.id
          }
        })
      })
  },
  methods: {
    setTime() {
      setTimeout(() => {
        var today = new Date()
        this.datum =
          today.getFullYear() +
          '-' +
          this.addNull(today.getMonth() + 1) +
          '-' +
          this.addNull(today.getDate())
        this.currenttime =
          this.addNull(parseInt(today.getHours())) +
          ':' +
          this.addNull(parseInt(today.getMinutes()))
        this.check_besuche()
        this.setTime()
      }, 60000)
    },
    check_besuche() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.uid)
        .collection('Besuche')
        .where('veranstaltung.datum', '==', this.datum)
        .orderBy('zutritt', 'desc')
        .get()
        .then((snap) => {
          this.besuche = []
          snap.forEach((doc) => {
            if (doc.data().verlassen.timestamp > Date.now()) {
              this.besuche.push(doc.data())
              var index = this.besuche.length - 1
              this.besuche[index].id = doc.id
            }
          })
        })
    },
    addNull(num) {
      if (num >= 10) {
        return num
      }
      return '0' + num
    },
    compare_time(t1, t2) {
      var zeit1 = t1.split(':')
      var zeit2 = t2.split(':')
      if (zeit1[0] > zeit2[0]) {
        return true
      } else {
        if (zeit1[0] == zeit2[0] && zeit1[1] > zeit2[1]) {
          return true
        } else {
          return false
        }
      }
    },
    checkout(index) {
      this.checking_out = index
      var event = this.besuche[index]
      if (this.compare_time(event.verlassen.uhrzeit, this.timer.uhrzeit)) {
        var verlassen = {
          datum: this.timer.datum,
          uhrzeit: this.timer.uhrzeit,
        }
        firebase
          .firestore()
          .collection('User')
          .doc(event.anwesenheit.verein)
          .collection('Anwesenheit')
          .doc(event.anwesenheit.id)
          .update({
            verlassen: verlassen,
          })
          .then((snap) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.uid)
              .collection('Besuche')
              .doc(event.id)
              .update({
                verlassen: verlassen,
                checkout: true,
              })
              .then(() => {
                this.checking_out = ''
                this.check_besuche()
              })
          })
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ]
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    parseDate1(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
